.match-header{
  width: 100%;
  margin-top: 3rem !important;
}

.team{
  width: 25%;
}

.team-players{
  width: 100%;
}

.team-player:hover{
  border-color: transparent;
}

.team-player > div >img{
  width: 60px;
  height: auto;
  padding: 3px;
  border: 2px solid var(--blue-color);
}

.team-captain-a,
.team-captain-b{
  width: 25px;
  height: auto;
  border-radius: 50%;
  padding: 3px;
  background-color: var(--blue-color);
  fill: var(--bg-container);
}

.team-captain-b{
  margin-left: -15px;
  margin-top: -55px;
  margin-right: -10px;
}

.team-captain-a{
  margin-left: -10px;
  margin-top: -55px;
  margin-right: -15px;
}

.match-vs{
  width: 30%;
  margin-top: 2rem;
}

.match-score{
  font-size: 3.5rem;
  font-family: 'Exo 2', sans-serif;
}

.match-player-username{
  text-transform: none;
}

.match-stage{
  font-weight: 400;
  letter-spacing: 1px;
}

.team-table-stats{
  width: 70%;
}

.team-table-tr{
  border-bottom: 1px solid var(--dark-grey-color);
  background-color: var(--bg-container-accent);
}

.team-table-player{
  width: 40px;
  height: auto;
  border: 2px solid var(--blue-color);
  padding: 3px;
}
