.profile-player{
    width: 70%;
    /*background-image: url('https://image.freepik.com/free-photo/colour-smoke-background_71163-197.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;*/
    border-radius: 5px;
}

.profile-player-picture{
    width: 170px;
    height: auto;
    border: 3px solid var(--blue-color);
    padding: 10px;
    box-shadow: 0px 1px 10px rgba(255, 255, 255, 0.4) !important; 
}

.btn-profile{
    width: 10rem;
    color: #fff;
    font-size: .8rem;
}

.btn-profile-message{
    background-color: var(--blue-color);
}

.btn-profile-message:hover{
    border: 1px solid var(--blue-color);
    background-color: transparent;
    color: var(--blue-color);
}

.btn-profile-addfriend{
    background-color: var(--orange-color);
}

.btn-profile-addfriend:hover{
    border: 1px solid var(--orange-color);
    background-color: transparent;
    color: var(--orange-color);
}

.user-not-found > svg{
    fill: var(--theme-blue);
    height: 8rem;
    width: auto;
    margin-bottom: 1rem;
}

.profile-nav{
    width: 70%;
    height: 2rem;
    border-bottom: 2px solid var(--bg-container-accent);
}

.profile-nav > h4{
    width: 25%;
    height: 100%;
    border-left: 1px solid var(--bg-container-accent);
    border-right: 1px solid var(--bg-container-accent);
}

.profile-nav h4:nth-child(1){
    border-left: 2px solid transparent;
}
.profile-nav h4:nth-child(4){
    border-right: 2px solid transparent;
}
