@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@200&display=swap');

body{
  font-family: 'Sora', sans-serif;
  color:#676767;
  background-color: #1e1e1e;
}

.bg-card {
  background-color:#171717;
}

.bg-sidebar-card-top{
  background-color: #353535;
}

.sidebar-separator-bottom{
  border-top: 1px solid #2e2e2e;
}

.search-icon{
  top: 50%;
  transform: translate(0, -50%);
}

.add-component-head{
  background-color: #696969;
}

.sidebar-item-selected{
  color: white;
  border-right: 2px solid white;
}
.sidebar-item{
  border-right: 2px solid transparent;
}
.sidebar-item:hover {
 color:#a1a0a0;
}

.steam-green {
  background: #7da10e;
}
