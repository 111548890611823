.admin-nav a:link {
  color: var(--dark-color);
  text-decoration: none;
  border-bottom: 1px solid transparent;
  border-color: transparent;
  transition: border 200ms ease 0s;
}

.admin-nav a:visited {
  color: var(--dark-color);
}

.admin-nav a:hover {
  color: var(--dark-color);
  border-color: currentcolor;
}

.admin-nav a:active {
  color: var(--dark-color);
}
