.mini-profile {
    border-radius: 5px;
    width: 100%;
}

.cap-miniprofile {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    fill: var(--blue-color);
}

.bg-container-accent {
    background: rgba(0, 0, 0, .75);
}

.bg-blue {
    background: var(--blue-color) !important;
}

.steam-profile-mini {
    fill: white;
    width: 14px;
    height: 14px;
}

.no-underline  {
    border-color: none;
}

.no-underline:hover  {
    border-color: transparent;
}

.mini-profile-picture {
    width: 50px;
    height: auto;
}

.mini-profile-name {
    flex: 1;
}
