footer {
    display: flex;
    background-color: var(--bg-dark-light);
    width: 100%;
    clear: both;
    margin-bottom: 0 !important;
}

.footer-logo {
    width: 300px;
}

.footer-rights{
    border-left: 3px solid var(--blue-color);
}

.footer-social{
    width: 25px;
    height: auto;
}

.footer-rights a:hover, 
.report-error{
    border-color: transparent !important;    
}

@media only screen and (max-width: 600px) {
    footer{
        height: 36rem;
    }
    .footer-rights{
        background-color: var(--blue-color);
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .footer-btns{
        justify-content: space-around !important;
        align-items: center !important;
        min-height: 3rem;
    }
    .footer-logo-container{
        align-items: center;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
