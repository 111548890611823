.ranking-item{
    list-style: none;
    width: 100%;
    background-color: #0e0f13;
}

.ranking-item-player{
    flex: 1;
}

.ranking-item-number{
    width: 3.5rem;
}

.ranking-item-picture img{
    width: 40px;
    height: auto;
    border: 2px solid var(--lightgrey-color);
}

.ranking-item-points{
    font-size: .9rem;
    color: var(--orange-color);
}

.ranking-table-points{
    height: 90% !important;
    border-left: 1px solid var(--dark-grey-color);
}

.ranking-item-podium{
    width: 30%;
}

.ranking-item-podium > span{
    color: var(--blue-color);
    margin-left: -100px;
    margin-bottom: -15px;
    font-family: var(--font-play);
    font-weight: 700;
}

.ranking-podium-picture{
    width: 100px !important;
    border: 2px solid var(--blue-color) !important;
    padding: 5px;
}

.ranking-table-picture{
    width: 45px;
    height: auto;
    padding: 3px;
    border: 2px solid var(--blue-color);
}

.ranking-table-player-username{
    flex: 1;
}

.ranking-table-player-username:hover,
.ranking-item-podium > a:hover{
    border-color: transparent;
}

.ranking-table-player-username > h4{
    text-transform: none !important;
}

.ranking-index{
    width: 3rem;
    color: var(--blue-color);
    font-family: var(--font-play);
    font-weight: 700;
    background-color: var(--bg-container);
    border-radius: 5px;
}
