.match-preview{
  background-color: var(--bg-container-accent);
  width: 60%;
}

.match-preview-team{
  width: 30%;
}
.match-preview-team>h4{
  font-size: 2.5rem;
}
.match-preview-score{
  width: 40%;
}
.match-preview-score>h5{
  font-size: .8rem;
}
.match-preview-state{
  background-color: var(--theme-blue);
  border-radius: 5px;
}
.match-preview-state>h5{
  font-size: 1rem;
}

.WARMUP,
.FINISHED,
.CANCELLED{
  border-radius: 5px;
}

.WARMUP{
  background-color: var(--orange-color);
}

.FINISHED{
  background-color: green;
}

.CANCELLED{
  background-color: red;
}
