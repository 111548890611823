.maps {
    width: 100%;
    flex-wrap: wrap;
}

.map-entry {
    width: 150px;
    height: 65px;
    border-radius: 2px 2px 0 0;
    cursor: pointer;
    border: 0;
}

.banning:hover::before {
    content: 'REMOVER';
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: rgba(255,0,0,.3);
    color: black;
    font-size: 1.5rem;
    font-family: var(--font-play);
    border-radius: 2px 2px 0 0;
    text-shadow: 2px 2px 5px #fff;
}

.map-ban {
    font-size: 8px;
    margin-top: 4px;
}

.map-name {
    width: 100%;
    border-radius: 0 0 2px 2px;
    background-color: var(--bg-container-accent);
}

.banned .map-ban {
    display: none;
}

.banned {
    filter: opacity(0.1);
}
