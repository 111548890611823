.timeleft {
    font-size: 14px;
}

.timer {
    margin-bottom: 16px;
}

.disclamer {
    margin: 14px 0;
    font-size: 14px;
    text-align: center;
}


.accpeted-match {
    width: 33px;
    -webkit-filter: invert(1) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
    filter: invert(1) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
}

.declined-match {
    width: 33px;
    -webkit-filter: invert(1) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
    filter: invert(1) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
}

.waiting-match {
    width: 33px;
    filter: invert(1) grayscale(0.5) contrast(0.5);
}

.vs{
    height: 2px;
    width: 280px;
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: #006466;
    border: 1px solid var(--blue-color);
}

.vs h4{
    margin-top: -15px;
    background-color: rgb(28, 32, 38);
    padding: 5px 10px;
    border: 1px solid var(--blue-color);
    border-radius: 5px;
}
